<template>
  <div class="card w-full bg-gray-100 shadow-lg">
    <div class="card-body">
      <div class="card-actions justify-end">
      </div>
      <p class="text-3xl">banner</p>
    </div>
  </div>
</template>
<script setup>
</script>