<template>
  <div>
    <SearchForm2></SearchForm2>
    <div class="space-y-4 px-8">
      <Banner></Banner>
      <Banner></Banner>
      <Banner></Banner>
    </div>
  </div>
</template>
<script setup>
</script>